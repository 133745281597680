import { defineStore } from 'pinia'
import Qs from "qs";
import MD5 from 'md5.js'
import numeral from 'numeral'

export const useUpsellsMetricsStore = defineStore({
    id: 'popMetrics',
    state() {
        return {
            conversionAggregations: {
                aggregations: {},
                currencies: [],
                granularity: 'day',
            },
            loadingConversionAggregations: false,
            actionsCount: {
                aggregations: {
                    add_to_cart_button_click: 0,
                    add_to_cart_close: 0,
                    add_to_cart_view: 0,
                    upsells_conversions_count: 0,
                    upsells_conversion_rate: 0,
                }
            },
            loadingActionsCount: false,
            granularityData: {
                dateFormats: {
                    hour: 'h A',
                    day: 'DD/MM/YYYY',
                    month: 'MM/YYYY',
                    year: 'YYYY',
                },
                texts: {
                    hour: 'Hourly',
                    day: 'Daily',
                    month: 'Monthly',
                    year: 'Yearly',
                }
            },
        }
    },
    getters: {
        requestDateFormat() {
            return 'YYYY-MM-DD'
        },
    },
    actions: {
        colorFromMD5(string) {
          return `#${new MD5().update(string).digest('hex').substring(0, 6)}`
        },
        totalAmountString(amount, currency) {
            return `${numeral(amount).format('0,0.00')} ${currency}`
        },
        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
        },
        encodedURIDate(date) {
            return encodeURIComponent(date.format(this.requestDateFormat))
        },
        async loadConversionAggregations({ startDate, endDate }) {
            this.loadingConversionAggregations = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/upsells/metrics/aggregations/conversions`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                this.conversionAggregations = response.data
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Something went wrong and we could not load conversions data.',
                    e
                })
            } finally {
                this.loadingConversionAggregations = false
            }
        },
        async loadActionsCount({ startDate, endDate }) {
            this.loadingActionsCount = true

            try {
                const params = {
                    filter: {
                        start_date: this.encodedURIDate(startDate),
                        end_date: this.encodedURIDate(endDate),
                    }
                }

                const response = await this.$nuxt.$axios.$get(
                    `${process.env.API_V1_URL}/upsells/metrics/aggregations/actions_count`,
                    {
                        params,
                        paramsSerializer: this.paramsSerializer
                    }
                )

                let aggregations = {
                  ...response.data.aggregations,
                  upsells_conversions_count: response.data.conversions_count,
                  upsells_conversion_rate: response.data.conversions_rate
                }

                this.actionsCount.aggregations = aggregations
            } catch (e) {
                this.$nuxt.$toasts.add({
                    type: 'error',
                    title: 'Something went wrong!',
                    subtitle: 'Something went wrong and we could not load actions data.',
                    e
                })
            } finally {
                this.loadingActionsCount = false
            }
        },
    }
})
